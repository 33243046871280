<template>
  <div class="personal-data">
    <van-nav-bar
      title="我要脱单"
      left-arrow
      @click-left="onClickLeft"
      :z-index="1000"
      :border="false"
    >
      <template slot="left">
        <van-icon name="arrow-left" color="#000" size="0.5rem" />
      </template>
    </van-nav-bar>
    <img class="avatar-img" :src="imgsrc" alt="" />
    <input
      type="text"
      class="nickname"
      v-model="nickname"
      placeholder="请填写您的昵称"
    />
    <div class="birthday-input" @click="showpop = true">
      <input
        type="text"
        placeholder="请填写您的生日"
        disabled
        class="birthday"
        v-model="time"
      />
      <van-icon class="right-icon" name="arrow-down" size="0.51rem" />
    </div>
    <div class="gender">
      <div
        class="gender-item"
        :class="{ 'selected-nan': gender == 1 }"
        @click="gender = 1"
      >
        <van-icon class-prefix="iconfont" name="nan1" />男生
      </div>
      <div
        class="gender-item"
        :class="{ 'selected-woman': gender == 2 }"
        @click="gender = 2"
      >
        <van-icon
          class-prefix="iconfont"
          name="iconfontshouyezhuyetubiao07"
        />女生
      </div>
    </div>
    <div class="birthday-input" @click="displayArea = true">
      <input
        type="text"
        placeholder="请选择园区"
        disabled
        class="birthday"
        v-model="region"
      />
      <van-icon class="right-icon" name="arrow-down" size="0.51rem" />
    </div>
    <div class="manifesto">
      <textarea
        maxlength="25"
        placeholder="输入你的爱情宣言，25个文字以内"
        v-model="manifesto"
      ></textarea>
    </div>
    <button class="submit" @click="single">我要脱单</button>
    <van-popup
      v-model="showpop"
      closeable
      position="bottom"
      class="birthday-pop"
      safe-area-inset-bottom
    >
      <div class="title">填写你的生日</div>
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        :show-toolbar="false"
        ref="datetimePicker"
        @confirm="confirm"
      />
      <button class="confirm" @click="areYouSure">确定</button>
    </van-popup>
    <van-popup
      v-model="displayArea"
      position="bottom"
      safe-area-inset-bottom
      style="height: 10rem"
    >
      <van-picker
        title="选择地区"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        value-key="name"
      />
    </van-popup>
  </div>
</template>

<script>
import { takeOffSingle, getGarden } from "@/api/my";
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2021, 0, 17),
      currentDate: new Date(2021, 0, 17),
      showpop: false,
      displayArea: false,
      columns: [],

      gender: 1,
      time: "",
      nickname: "",
      imgsrc: "",
      region: "",
      manifesto: "",
      yuanquId: ""
    };
  },
  created() {
    this.getGardenList();
  },
  mounted() {
    this.nickname = sessionStorage.getItem("nickname");
    this.imgsrc = sessionStorage.getItem("headimgurl");
  },
  methods: {
    onClickLeft() {
      console.log(666);
      this.$router.back();
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    areYouSure() {
      this.$refs.datetimePicker.getPicker().confirm();
    },
    confirm(e) {
      this.time = dayjs(e).format("YYYY-MM-DD");

      this.showpop = false;
    },

    // eslint-disable-next-line no-unused-vars
    onConfirm(value, index) {
      this.region = value.name;
      this.yuanquId = value.id;
      this.displayArea = false;
    },

    onCancel() {
      this.displayArea = false;
    },
    async single() {
      if (!this.nickname) {
        return this.$toast("请填写您的昵称");
      }
      if (!this.time) {
        return this.$toast("请填写您的生日");
      }
      if (!this.gender) {
        return this.$toast("请填写您的性别");
      }

      if (!this.region) {
        return this.$toast("请填写园区");
      }
      if (!this.manifesto) {
        return this.$toast("请填写您的爱情宣言");
      }
      const res = await takeOffSingle({
        nickname: this.nickname,
        sex: this.gender,
        description: this.manifesto,
        garden_id: this.yuanquId,
        birthday: this.time
      });
      console.log(res.data.code, 2111);
      if (res.data.code == 200) {
        sessionStorage.description = this.manifesto;
        this.$router.push("/tuodan");
      } else {
        this.$toast(res.data.message);
      }
    },
    async getGardenList() {
      try {
        const res = await getGarden();
        this.columns = res.data.data.list;
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$bgccolor: rgba(243, 245, 249, 0.6);
.personal-data {
  min-height: 100vh;
  background: url("./../assets/img/01.png") 0 0 no-repeat;
  background-size: 100%;
  /deep/ .van-nav-bar {
    background: rgba(255, 255, 255, 0);
  }
  .nickname {
    width: 625px;
    height: 125px;
    background: $bgccolor;
    border-radius: 63px;
    border: 0;
    text-align: center;
    display: block;
    font-size: 38px;
    font-weight: 600;
    color: #000000;
    margin: 14px auto;
  }
  .avatar-img {
    width: 153px;
    height: 153px;
    border-radius: 50%;
    display: block;
    margin: 50px auto;
  }
  .birthday-input {
    display: block;
    margin: auto;
    position: relative;
    width: 625px;
    height: 125px;
    .birthday {
      width: 100%;
      height: 100%;
      padding: 0;
      background: $bgccolor;
      border-radius: 63px;
      border: 0;
      text-align: center;
      display: block;
      font-size: 38px;
      font-weight: 600;
      color: #000000;
      margin: 28px auto;
    }
    .right-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 38px;
      width: 38px;
      height: 38px;
      margin: auto;
    }
  }

  .nickname::placeholder,
  .birthday::placeholder {
    color: #999999;
    font-weight: 300;
  }
  .gender {
    width: 625px;
    display: flex;
    margin: 24px auto;
    justify-content: space-between;
    &-item {
      width: 299px;
      height: 125px;
      //   border: 3px solid #009fff;
      border-radius: 63px;
      line-height: 125px;
      text-align: center;
      font-size: 35px;
      font-weight: 400;
      color: #999999;
      background: $bgccolor;
      box-sizing: border-box;
    }
    .selected-nan {
      border: 3px solid #009fff;
      color: #000000;
      background: #ffffff;
      .iconfont {
        color: #009fff;
      }
    }
    .selected-woman {
      border: 3px solid #ff5e9c;
      color: #000000;
      background: #ffffff;
      .iconfont {
        color: #ff5e9c;
      }
    }
  }
  .manifesto {
    width: 625px;
    height: 197px;
    background: $bgccolor;
    border-radius: 35px;
    margin: 28px auto;
    padding: 20px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      height: 100%;
      border: 0;
      background: rgba(0, 0, 0, 0);
      resize: none;
      font-size: 35px;
    }
  }
  .submit {
    width: 625px;
    height: 97px;
    background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
    border-radius: 45px;
    border: 0;
    margin: auto;
    display: block;
    font-size: 35px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 80px;
  }
  .birthday-pop {
    height: 903px;
    .title {
      height: 120px;
      line-height: 120px;
      padding: 0 35px;
      font-size: 35px;
      font-weight: bold;
      color: #000000;
    }
    .confirm {
      width: 625px;
      height: 97px;
      background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
      border-radius: 45px;
      border: 0;
      display: block;
      margin: auto;
      margin-top: 50px;
      font-size: 35px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
